import React, {Component} from 'react'
import {StyleSheet, View} from 'react-native'
import IViewHeader from './IViewHeader'
import IViewBody from './IViewBody'
import IViewSync from './IViewSync'

class IView extends Component {
  render () {
    const {iViewWidth, iViewHeight, videoHeight, videoWidth} = this.props

    return (
      <View style={styles.iViewStyle}>
        <IViewHeader
          title={this.props.title}
          iViewWidth={iViewWidth}
          videoHeight={videoHeight}
        />
        <IViewSync
          iViewHeight={iViewHeight}
          iViewWidth={iViewWidth}
          videoHeight={videoHeight}
          videoWidth={videoWidth}
          timeLine={this.props.timeLine}
          onTimeLineChange={this.props.onTimeLineChange}
        />
        <IViewBody
          onTimePointPress={this.props.onTimePointPress}
          data={this.props.data}
          notes={this.props.notes}
          iViewWidth={iViewWidth}
          videoHeight={videoHeight}
          videoWidth={videoWidth}
          onNoteCreate={this.props.onNoteCreate}
          onNoteRemove={this.props.onNoteRemove}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  iViewStyle: {},
})

export default IView
