import React, {Component} from 'react'
import {StyleSheet, View} from 'react-native'
import {ButtonGroup, Text} from 'react-native-elements'

class IView extends Component {
  render () {
    const {iViewHeight, videoHeight} = this.props
    const SYNC_HEIGHT = iViewHeight / 15
    const buttonGroupHeight = videoHeight / 15
    const buttonGroupTitleSize = buttonGroupHeight / 3
    const textHeight = buttonGroupHeight / 2.5

    return (
      <View
        style={[
          styles.syncViewStyle,
          {
            height: SYNC_HEIGHT,
          },
        ]}>
        <Text
          style={[
            styles.syncTextStyle,
            {
              fontSize: textHeight,
            },
          ]}>
          Sync content with video ?
        </Text>
        <ButtonGroup
          onPress={this.props.onTimeLineChange}
          selectedIndex={this.props.timeLine}
          buttons={['Yes', 'No']}
          containerStyle={[
            styles.syncButtonGroupContainerStyle,
            {
              height: SYNC_HEIGHT,
            },
          ]}
          selectedButtonStyle={{
            backgroundColor: 'transparent',
          }}
          innerBorderStyle={{
            width: 0,
          }}
          buttonStyle={{
            height: SYNC_HEIGHT,
          }}
          textStyle={[
            styles.syncButtonGroupTitleStyle,
            {
              fontSize: buttonGroupTitleSize,
            },
          ]}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  syncButtonGroupContainerStyle: {
    margin: 0,
    flex: 0.3,
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
  syncButtonGroupTitleStyle: {
    fontWeight: 'normal',
  },
  syncViewStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingTop: 5,
    paddingBottom: 2,
  },
  syncTextStyle: {
    fontWeight: 'normal',
    flex: 0.7,
    color: 'white',
  },
})

export default IView
