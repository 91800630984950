import React, {Component} from 'react'
import {StyleSheet} from 'react-native'

import {Icon} from 'react-native-elements'
import * as Animatable from 'react-native-animatable'

import IView from './IView'

export default class IViewLayout extends Component {
  render () {
    const videoHeight = this.props.iViewHeight
    const iconHeight = videoHeight / 15
    const iconSize = iconHeight / 2
    return (
      <Animatable.View
        ref={this.props.iViewReference}
        style={{
          position: 'absolute',
          right: 0,
          height: (this.props.iViewHeight * 14) / 15,
          width: this.props.iViewWidth,
          backgroundColor: '#00000050',
          borderRadius: 5,
        }}>
        <Icon
          size={iconSize}
          name={this.props.iViewClosed ? 'times' : 'times'}
          type={'font-awesome-5'}
          color={'white'}
          onPress={this.props.onIconPress}
          containerStyle={styles.switcherIconContainerStyle}
        />
        <IView
          videoHeight={this.props.videoHeight}
          videoWidth={this.props.videoWidth}
          iViewWidth={this.props.iViewWidth}
          iViewHeight={this.props.iViewHeight}
          onNoteCreate={this.props.onNoteCreate}
          data={this.props.data}
          notes={this.props.notes}
          timeLine={this.props.timeLine}
          onTimePointPress={this.props.onTimePointPress}
          onTimeLineChange={this.props.onTimeLineChange}
          title={this.props.title}
        />
      </Animatable.View>
    )
  }
}

const styles = StyleSheet.create({
  switcherIconContainerStyle: {
    backgroundColor: '#ffffff50',
    borderRadius: 10,
  },
})
