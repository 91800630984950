import React, {Component} from 'react'

import {ScrollView, View} from 'react-native'

import {Divider, Icon} from 'react-native-elements'

import isEqual from 'lodash/isEqual'

import IViewTextNotesView from '../components/notes/IViewTextNotesView'
import IViewCodeNotesView from '../components/notes/IViewCodeNotesView'
import IViewLinkNotesView from '../components/notes/IViewLinkNotesView'
import IViewDocNotesView from '../components/notes/IViewDocNotesView'

class IViewBodyNotes extends Component {
  shouldComponentUpdate (nextProps, nextState) {
    if (!isEqual(this.props, nextProps)) {
      return true
    } else {
      return false
    }
  }

  loadDataViews = () => {
    const {notes} = this.props
    let views = []
    notes.forEach(note => {
      switch (note.type) {
        case 0:
          views.push(
            <IViewTextNotesView
              onTimePointPress={this.props.onTimePointPress}
              note={note}
              width={this.props.iViewWidth}
              height={this.props.videoHeight}
              videoHeight={this.props.videoHeight}
              videoWidth={this.props.videoWidth}
              onNoteRemove={this.props.onNoteRemove}
            />,
          )
          break
        case 1:
          views.push(
            <IViewLinkNotesView
              onTimePointPress={this.props.onTimePointPress}
              note={note}
              width={this.props.iViewWidth}
              height={this.props.videoHeight}
              videoHeight={this.props.videoHeight}
              videoWidth={this.props.videoWidth}
              onNoteRemove={this.props.onNoteRemove}
            />,
          )
          break
        case 2:
          views.push(
            <IViewDocNotesView
              onTimePointPress={this.props.onTimePointPress}
              note={note}
              width={this.props.iViewWidth}
              height={this.props.videoHeight}
              videoHeight={this.props.videoHeight}
              videoWidth={this.props.videoWidth}
              onNoteRemove={this.props.onNoteRemove}
            />,
          )
          break
        case 3:
          views.push(
            <IViewCodeNotesView
              onTimePointPress={this.props.onTimePointPress}
              note={note}
              width={this.props.iViewWidth}
              height={this.props.videoHeight}
              videoHeight={this.props.videoHeight}
              videoWidth={this.props.videoWidth}
              onNoteRemove={this.props.onNoteRemove}
            />,
          )
          break

        default:
          break
      }
      views.push(
        <Divider style={{height: 10, backgroundColor: 'transparent'}} />,
      )
    })
    return views
  }

  componentDidUpdate () {
    this.scrollRef.scrollToEnd()
  }

  scrollVewRef = ref => {
    this.scrollRef = ref
  }

  onPress = () => {
    this.scrollRef.scrollTo({x: 0, y: 0, animated: true})
  }

  render () {
    const videoHeight = this.props.videoHeight
    const height = (videoHeight * 8) / 15
    return (
      <View>
        <ScrollView
          ref={this.scrollVewRef}
          showsVerticalScrollIndicator={false}
          style={{height: height - 30, padding: 10}}>
          {this.loadDataViews()}
        </ScrollView>
        <Icon
          onPress={this.onPress}
          name='chevron-circle-up'
          color='brown'
          type='font-awesome-5'
          size={15}
          containerStyle={{
            padding: 10,
          }}
        />
      </View>
    )
  }
}

export default IViewBodyNotes
