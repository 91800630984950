import React, {Component} from 'react'
import {StyleSheet} from 'react-native'
import {Text} from 'react-native-elements'

class IViewTextView extends Component {
  constructor (params) {
    super(params)
    this.state = {
      isMoreClicked: false,
    }
  }

  getTextHeight = height => {
    return height / 4
  }

  onMorePressed = () => {
    this.setState({
      isMoreClicked: !this.state.isMoreClicked,
    })
  }

  getSecondsInReadableFormat = () => {
    const sec_num = this.props.timePoint / 1000
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor((sec_num - hours * 3600) / 60)
    var seconds = sec_num - hours * 3600 - minutes * 60

    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return hours + ':' + minutes + ':' + seconds
  }

  render () {
    return (
      <Text
        onPress={() => {
          this.props.onTimePointPress(this.props.timePoint)
        }}
        style={[
          styles.timepointStyle,
          {
            fontSize: this.props.timeStampSize,
            padding: this.props.timeStampSize / 5,
            color: this.props.color ? this.props.color : 'grey',
          },
        ]}>
        {' '}
        {this.getSecondsInReadableFormat()}{' '}
      </Text>
    )
  }
}

const styles = StyleSheet.create({
  timepointStyle: {
    alignSelf: 'flex-end',
  },
})

export default IViewTextView
