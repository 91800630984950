/* eslint-disable no-template-curly-in-string */
import React, {Component} from 'react'
import {
  StyleSheet,
  View,
  Linking,
  Alert,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import {Text, Avatar} from 'react-native-elements'

async function OpenURL (url) {
  const supported = await Linking.canOpenURL(url)
  if (supported) {
    await Linking.openURL(url)
  } else {
    Alert.alert('No app to open this URL: ${url}')
  }
}

class IViewLinkView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isMounted: false,
      title: '',
      description: '',
      url: '',
      image: '',
    }
  }
  componentDidMount () {
    var data = {
      key: '14f4e0102f66b176837f885406f83d6e',
      q: this.props.note.body,
    }
    fetch('https://api.linkpreview.net', {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(response => {
        this.setState({
          isMounted: true,
          title: response.title,
          description: response.description,
          url: response.url,
          image: response.image,
        })
      })
  }

  onPress = () => {
    OpenURL(this.state.url)
  }

  getLinkHeight = height => {
    return height / 8
  }

  onRemoveNote = () => {
    this.props.onNoteRemove(this.props.note)
  }

  render () {
    const {width, height} = this.props
    const height_ = this.getLinkHeight(height)
    const avatarSize = height_ / 2.5
    const titleAndUrlFontSize = height_ / 6

    const linView = width - avatarSize - 40

    return (
      <View
        style={{
          borderRadius: 5,
          paddingHorizontal: 10,
          paddingTop: 5,
          paddingBottom: 2,
          backgroundColor: 'transparent',
        }}>
        <Text
          onPress={this.onRemoveNote}
          style={[
            styles.removeTextStyle,
            {
              fontSize: titleAndUrlFontSize / 1.5,
            },
          ]}>
          Remove
        </Text>
        {this.state.isMounted ? (
          <TouchableOpacity onPress={this.onPress}>
            <View style={styles.viewStyle}>
              <Avatar
                source={{
                  uri: this.state.image,
                }}
                rounded
                avatarContainerStyle={styles.avatarContainerStyle}
                size={avatarSize}
              />
              <View
                style={{
                  marginHorizontal: 10,
                  width: linView,
                }}>
                <Text
                  numberOfLines={1}
                  style={[
                    styles.titleStyle,
                    {
                      fontSize: titleAndUrlFontSize,
                    },
                  ]}>
                  {this.state.title}
                </Text>
                <Text
                  numberOfLines={1}
                  style={[
                    styles.urlStyle,
                    {
                      fontSize: titleAndUrlFontSize,
                    },
                  ]}>
                  {this.state.url}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        ) : (
          <ActivityIndicator />
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  removeTextStyle: {
    backgroundColor: '#1c1e21',
    padding: 5,
    borderRadius: 3,
    color: 'white',
    alignSelf: 'flex-end',
  },
  avatarContainerStyle: {},
  viewStyle: {
    flexDirection: 'row',
  },
  urlStyle: {color: '#007599'},
  titleStyle: {
    fontWeight: 'bold',
    color: 'black',
  },
  textStyle: {
    color: '#243447',
  },
})

export default IViewLinkView
