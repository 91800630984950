import React, {Component} from 'react'
import {Icon} from 'react-native-elements'

export default class IViewNoteControl extends Component {
  render () {
    return (
      <Icon
        onPress={this.props.onIViewNoteControlPress}
        name={'pen-square'}
        color={this.props.iViewNoteClosed ? 'grey' : 'white'}
        containerStyle={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        type='font-awesome-5'
        size={15}
      />
    )
  }
}
