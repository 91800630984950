import React, {Component} from 'react'
import {StyleSheet, View, Clipboard} from 'react-native'
import {Text} from 'react-native-elements'
import TimePointView from './TimePointView'
import find from 'lodash/find'

class IViewCodeView extends Component {
  constructor (params) {
    super(params)
    this.state = {
      isMoreClicked: false,
      isCopied: false,
      isNoted: false,
    }
  }

  getTextHeight = height => {
    return height / 4
  }

  copyText = () => {
    this.setState(
      {
        isCopied: true,
      },
      () => Clipboard.setString(this.props.data.body),
    )
    setTimeout(() => {
      this.setState({
        isCopied: false,
      })
    }, 1000)
  }

  noteText = () => {
    this.props.onNoteCreate(this.props.data)
  }

  onMorePressed = () => {
    this.setState({
      isMoreClicked: !this.state.isMoreClicked,
    })
  }

  render () {
    const {height} = this.props
    const height_ = this.getTextHeight(height)
    const fontSize = height_ / 10
    const timeStampSize = height_ / 16

    return (
      <View
        style={{
          borderRadius: 5,
          paddingHorizontal: 10,
          paddingTop: 5,
          paddingBottom: 2,
          backgroundColor: '#292d3e',
        }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 1,
          }}>
          <Text
            onPress={this.noteText}
            style={[
              styles.copyTextStyle,
              {
                fontSize: fontSize / 1.5,
              },
            ]}>
            {!find(this.props.notes, this.props.data) ? 'Note' : 'Noted'}
          </Text>
          <Text
            onPress={this.copyText}
            style={[
              styles.copyTextStyle,
              {
                fontSize: fontSize / 1.5,
              },
            ]}>
            {!this.state.isCopied ? 'Copy' : 'Copied'}
          </Text>
        </View>
        <Text
          onPress={this.copyText}
          numberOfLines={this.state.isMoreClicked ? null : 4}
          style={[
            styles.textStyle,
            {
              fontSize: fontSize,
            },
          ]}>
          {this.props.data.body}
        </Text>
        <Text
          selectable={true}
          style={[
            styles.moreTextStyle,
            {
              fontSize: fontSize / 1.5,
            },
          ]}
          onPress={this.onMorePressed}>
          {!this.state.isMoreClicked ? 'Read more' : 'Show less'}
        </Text>
        <TimePointView
          timePoint={this.props.data.timepoint}
          timeStampSize={timeStampSize}
          onTimePointPress={this.props.onTimePointPress}
          color={'silver'}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  copyTextStyle: {
    backgroundColor: '#1c1e21',
    padding: 5,
    borderRadius: 3,
    color: 'white',
    alignSelf: 'flex-end',
  },
  timepointStyle: {
    alignSelf: 'flex-end',
    color: 'grey',
  },
  textStyle: {
    color: 'white',
    letterSpacing: 0.5,
  },
  moreTextStyle: {
    color: 'gold',
    alignSelf: 'flex-end',
  },
})

export default IViewCodeView
