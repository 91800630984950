import React, {Component} from 'react'
import {StyleSheet, View} from 'react-native'
import {Text, Icon} from 'react-native-elements'
import TimePointView from './TimePointView'

import FileIcon from '../../custom-icons/icons'
import find from 'lodash/find'

const lightColorsObject = {
  a: '#7678ed',
  b: '#941b0c',
  s: '#a7c957',
  d: '#6a6b83',
  e: '#7b2cbf',
  f: '#f43f30',
  g: '#1fa6b8',
  h: '#ff6b6b',
  i: '#84a98c',
  j: '#9f86c0',
  k: '#ed4f7a',
  l: '#e63946',
  m: '#00bbf9',
  n: '#1a936f',
  o: '#d08c60',
  p: '#3bceac',
  q: '#76949f',
  r: '#808000',
  c: '#0067b8',
  t: '#df7373',
  u: '#0077b6',
  v: '#f77f00',
  w: '#02c39a',
  x: '#f3722c',
  y: '#5c677d',
  z: '#00b4d8',
  '3': 'yellow',
  '4': 'purple',
}

const files = [
  '7zip',
  'aac',
  'ai',
  'apk',
  'asc',
  'avi',
  'css',
  'csv',
  'dll',
  'dmg',
  'dng',
  'doc',
  'eps',
  'exe',
  'fff',
  'file',
  'flv',
  'gif',
  'gis',
  'gpx',
  'heic',
  'html',
  'java',
  'jp2',
  'jpg',
  'kml',
  'kmz',
  'mov',
  'mp3',
  'mpg',
  'nmea',
  'ogg',
  'osm',
  'otf',
  'pdf',
  'ppt',
  'ps',
  'psd',
  'py',
  'qti',
  'rar',
  'raw',
  'tar',
  'tif',
  'ttf',
  'txt',
  'wav',
  'woff',
  'xls',
  'zip',
]

class IViewDocView extends Component {
  constructor (params) {
    super(params)
    this.state = {
      isNoted: false,
    }
  }

  noteText = () => {
    this.setState({
      isNoted: true,
    })
    this.props.onNoteCreate(this.props.data)
  }

  getDocHeight = height => {
    return height / 8
  }

  getFileExtension = () => {
    const path = this.props.data.body
    const extension = path.split('.').pop()
    if (files.includes(extension.toLowerCase())) {
      return extension.toLowerCase()
    } else {
      return 'file'
    }
  }

  getIconColor = () => {
    const ext = this.getFileExtension()
    const letter = ext.charAt(ext.length - 1)
    return lightColorsObject[letter]
  }

  getFileName = () => {
    let path = this.props.data.body
    return path.split('/').pop()
  }

  render () {
    const {height} = this.props
    const height_ = this.getDocHeight(height)
    const fontSize = height_ / 6
    const iconSize = height_ / 3
    const iconSizeDownload = height_ / 6
    const timeStampSize = height_ / 8
    return (
      <View style={styles.viewStyle}>
        <Text
          onPress={this.noteText}
          style={[
            styles.copyTextStyle,
            {
              fontSize: fontSize / 1.2,
            },
          ]}>
          {!find(this.props.notes, this.props.data) ? 'Note' : 'Noted'}
        </Text>

        <View style={styles.bottomViewStyle}>
          <FileIcon
            name={this.getFileExtension()}
            type='iv-player-icons'
            size={iconSize}
            color={this.getIconColor()}
          />
          <Text
            numberOfLines={1}
            style={[
              styles.nameStyle,
              {
                fontSize: fontSize,
              },
            ]}>
            {this.getFileName()}
          </Text>
          <Icon
            onPress={() => {
              var link = document.createElement('a')
              link.download = this.getFileName()
              link.href = this.props.data.body
              link.click()
            }}
            name='download'
            type='font-awesome-5'
            size={iconSizeDownload}
            color='black'
          />
        </View>
        <TimePointView
          timePoint={this.props.data.timepoint}
          timeStampSize={timeStampSize}
          onTimePointPress={this.props.onTimePointPress}
          color={'black'}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  copyTextStyle: {
    backgroundColor: 'white',
    padding: 2,
    borderRadius: 3,
    color: 'black',
    alignSelf: 'flex-start',
  },
  bottomViewStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  nameStyle: {
    color: 'black',
    marginLeft: 10,
    flexGrow: 1,
  },
  viewStyle: {
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingTop: 5,
    paddingBottom: 2,
    backgroundColor: '#F9F1F0',
  },
  imageStyle: {
    resizeMode: 'contain',
  },
})

export default IViewDocView
