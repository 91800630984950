import React, {Component} from 'react'
import {StyleSheet, View} from 'react-native'
import {Text} from 'react-native-elements'

class IViewHeader extends Component {
  render () {
    const {videoHeight} = this.props
    const HEADER_HEIGHT = videoHeight / 15
    const FONT_SIZE = HEADER_HEIGHT / 2.5
    return (
      <View
        style={[
          styles.headerStyle,
          {
            height: HEADER_HEIGHT,
            justifyContent: this.props.center ? 'space-between' : 'flex-start',
            flexDirection: 'row',
          },
        ]}>
        <Text
          numberOfLines={1}
          style={{
            fontSize: FONT_SIZE,
            color: 'brown',
          }}>
          {this.props.title}
        </Text>
        <Text
          onPress={this.props.notesExport}
          numberOfLines={1}
          style={{
            fontSize: FONT_SIZE,
            color: 'brown',
            backgroundColor: 'white',
            padding: 2,
          }}>
          Download
        </Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  headerStyle: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingTop: 5,
    paddingBottom: 2,
  },
})

export default IViewHeader
