import React, {Component} from 'react'
import {Icon} from 'react-native-elements'

export default class IViewControl extends Component {
  render () {
    return (
      <Icon
        onPress={this.props.onIViewControlPress}
        name={'magic'}
        color={this.props.iViewClosed ? 'grey' : 'white'}
        containerStyle={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        type='font-awesome-5'
        size={15}
      />
    )
  }
}
