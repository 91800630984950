import React, {Component} from 'react'
import {StyleSheet, View} from 'react-native'
import IViewHeader from './IViewHeader'
import IViewBodyNotes from './IViewBodyNotes'

class IViewNotes extends Component {
  render () {
    const {iViewWidth, videoHeight, videoWidth} = this.props

    return (
      <View style={styles.iViewStyle}>
        <IViewHeader
          title={'Notes'}
          iViewWidth={iViewWidth}
          videoHeight={videoHeight}
          center={true}
          notesExport={this.props.notesExport}
        />
        <IViewBodyNotes
          onTimePointPress={this.props.onTimePointPress}
          notes={this.props.notes}
          iViewWidth={iViewWidth}
          videoHeight={videoHeight}
          videoWidth={videoWidth}
          onNoteRemove={this.props.onNoteRemove}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  iViewStyle: {},
})

export default IViewNotes
