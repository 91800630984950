import React, {Component} from 'react'

import {Player, ControlBar, BigPlayButton} from 'video-react'

import IViewControl from './view/IViewControl'
import IViewNotesControl from './notes/IViewNotesControl'

import IViewLayout from './view/IViewLayout'

import IViewNotesLayout from './notes/IViewNotesLayout'

class VideoPlayer extends Component {
  render () {
    return (
      <Player ref={this.props.reference} {...this.props}>
        <ControlBar disableCompletely={false} autoHide={false}>
          <IViewControl
            order={7}
            iViewClosed={this.props.iViewClosed}
            onIViewControlPress={this.props.onIViewControlPress}
          />
          <IViewNotesControl
            order={3}
            iViewNoteClosed={this.props.iViewNoteClosed}
            onIViewNoteControlPress={this.props.onIViewNoteControlPress}
          />
        </ControlBar>
        <BigPlayButton position={'center'} />
        <IViewLayout order={2} {...this.props} />
        <IViewNotesLayout order={2} {...this.props} />
      </Player>
    )
  }
}

export default VideoPlayer
