import React, {Component} from 'react'
import {StyleSheet, View} from 'react-native'
import {Text} from 'react-native-elements'

class IViewCodeView extends Component {
  constructor (params) {
    super(params)
    this.state = {
      isMoreClicked: false,
    }
  }

  getTextHeight = height => {
    return height / 4
  }

  onMorePressed = () => {
    this.setState({
      isMoreClicked: !this.state.isMoreClicked,
    })
  }

  onRemoveNote = () => {
    this.props.onNoteRemove(this.props.note)
  }

  render () {
    const {height} = this.props
    const height_ = this.getTextHeight(height)
    const fontSize = height_ / 10

    return (
      <View
        style={{
          borderRadius: 5,
          paddingHorizontal: 10,
          paddingTop: 5,
          paddingBottom: 2,
          backgroundColor: '#292d3e',
        }}>
        <Text
          onPress={this.onRemoveNote}
          style={[
            styles.removeTextStyle,
            {
              fontSize: fontSize / 1.5,
            },
          ]}>
          Remove
        </Text>
        <Text
          numberOfLines={this.state.isMoreClicked ? null : 4}
          style={[
            styles.textStyle,
            {
              fontSize: fontSize,
            },
          ]}>
          {this.props.note.body}
        </Text>
        <Text
          selectable={true}
          style={[
            styles.moreTextStyle,
            {
              fontSize: fontSize / 1.5,
            },
          ]}
          onPress={this.onMorePressed}>
          {!this.state.isMoreClicked ? 'Read more' : 'Show less'}
        </Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  removeTextStyle: {
    backgroundColor: '#1c1e21',
    padding: 5,
    borderRadius: 3,
    color: 'white',
    alignSelf: 'flex-end',
  },
  textStyle: {
    color: 'white',
    letterSpacing: 0.5,
  },
  moreTextStyle: {
    color: 'gold',
    alignSelf: 'flex-end',
  },
})

export default IViewCodeView
