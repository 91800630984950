import React, {Component} from 'react'
import {StyleSheet} from 'react-native'

import {Icon} from 'react-native-elements'
import * as Animatable from 'react-native-animatable'

import IViewNotes from './IViewNotes'

export default class IViewNotesLayout extends Component {
  render () {
    const videoHeight = this.props.iViewHeight
    const iconHeight = videoHeight / 15
    const iconSize = iconHeight / 2
    return (
      <Animatable.View
        ref={this.props.iViewNoteReference}
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: (this.props.iViewHeight * 10) / 15,
          width: this.props.iViewWidth,
          backgroundColor: 'wheat',
          borderRadius: 5,
          marginBottom: (this.props.iViewHeight * 1) / 15,
        }}>
        <Icon
          size={iconSize}
          name={this.props.iViewNotesClosed ? 'times' : 'times'}
          type={'font-awesome-5'}
          color={'black'}
          onPress={this.props.onNoteIconPress}
          containerStyle={styles.switcherIconContainerStyle}
        />
        <IViewNotes
          videoHeight={this.props.videoHeight}
          videoWidth={this.props.videoWidth}
          iViewWidth={this.props.iViewWidth}
          iViewHeight={this.props.iViewHeight}
          notes={this.props.notes}
          timeLine={this.props.timeLine}
          onTimePointPress={this.props.onTimePointPress}
          onTimeLineChange={this.props.onTimeLineChange}
          onNoteRemove={this.props.onNoteRemove}
          notesExport={this.props.notesExport}
        />
      </Animatable.View>
    )
  }
}

const styles = StyleSheet.create({
  switcherIconContainerStyle: {
    backgroundColor: '#ffffff50',
    borderRadius: 10,
  },
})
