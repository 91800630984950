import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

// import App from './App'
// ReactDOM.render(<App />, document.getElementById('iv-player-root'))

import PublicApp from './PublicApp'
const el = document.getElementsByClassName('iv-player-front')
Object.keys(el).forEach((element, index) => {
  ReactDOM.render(
    <PublicApp
      id={el[element].getAttribute('data-id')}
      timeline={el[element].getAttribute('data-timeline')}
      title={el[element].getAttribute('data-title')}
    />,
    el[element],
  )
})
serviceWorker.unregister()
