import React, {Component} from 'react'
import {StyleSheet, View} from 'react-native'
import {Text} from 'react-native-elements'

class IViewHeader extends Component {
  render () {
    const {videoHeight} = this.props
    const HEADER_HEIGHT = videoHeight / 15
    const FONT_SIZE = HEADER_HEIGHT / 2.5
    return (
      <View
        style={[
          styles.headerStyle,
          {
            height: HEADER_HEIGHT,
            alignItems: this.props.center ? 'center' : 'flex-start',
          },
        ]}>
        <Text
          numberOfLines={1}
          style={{
            fontSize: FONT_SIZE,
            color: 'white',
          }}>
          {this.props.title}
        </Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  headerStyle: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingTop: 5,
    paddingBottom: 2,
  },
})

export default IViewHeader
