import React, {Component} from 'react'

import {ScrollView, View} from 'react-native'

import {Divider, Icon} from 'react-native-elements'

import isEqual from 'lodash/isEqual'

import IViewTextView from '../components/view/IViewTextView'
import IViewCodeView from '../components/view/IViewCodeView'
import IViewLinkView from '../components/view/IViewLinkView'
import IViewDocView from '../components/view/IViewDocView'

class IViewBody extends Component {
  constructor (params) {
    super(params)
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      !isEqual(this.props.data, nextProps.data) ||
      !isEqual(this.props.notes, nextProps.notes) ||
      !isEqual(this.props.videoHeight, nextProps.videoHeight)
    ) {
      return true
    } else {
      return false
    }
  }

  loadDataViews = () => {
    const {data} = this.props
    let views = []
    data.forEach(element => {
      switch (element.type) {
        case 0:
          views.push(
            <IViewTextView
              onTimePointPress={this.props.onTimePointPress}
              data={element}
              notes={this.props.notes}
              width={this.props.iViewWidth}
              height={this.props.videoHeight}
              videoHeight={this.props.videoHeight}
              videoWidth={this.props.videoWidth}
              onNoteCreate={this.props.onNoteCreate}
            />,
          )
          break
        case 1:
          views.push(
            <IViewLinkView
              onTimePointPress={this.props.onTimePointPress}
              data={element}
              notes={this.props.notes}
              width={this.props.iViewWidth}
              height={this.props.videoHeight}
              videoHeight={this.props.videoHeight}
              videoWidth={this.props.videoWidth}
              onNoteCreate={this.props.onNoteCreate}
            />,
          )
          break
        case 2:
          views.push(
            <IViewDocView
              onTimePointPress={this.props.onTimePointPress}
              data={element}
              notes={this.props.notes}
              width={this.props.iViewWidth}
              height={this.props.videoHeight}
              videoHeight={this.props.videoHeight}
              videoWidth={this.props.videoWidth}
              onNoteCreate={this.props.onNoteCreate}
            />,
          )
          break
        case 3:
          views.push(
            <IViewCodeView
              onTimePointPress={this.props.onTimePointPress}
              data={element}
              notes={this.props.notes}
              width={this.props.iViewWidth}
              height={this.props.videoHeight}
              videoHeight={this.props.videoHeight}
              videoWidth={this.props.videoWidth}
              onNoteCreate={this.props.onNoteCreate}
            />,
          )
          break

        default:
          break
      }
      views.push(
        <Divider style={{height: 10, backgroundColor: 'transparent'}} />,
      )
    })
    return views
  }

  componentDidUpdate () {
    this.scrollRef.scrollToEnd()
  }

  scrollVewRef = ref => {
    this.scrollRef = ref
  }

  onPress = () => {
    this.scrollRef.scrollTo({x: 0, y: 0, animated: true})
  }

  render () {
    const videoHeight = this.props.videoHeight
    const height = (videoHeight * 11) / 15
    return (
      <View>
        <ScrollView
          ref={this.scrollVewRef}
          showsVerticalScrollIndicator={false}
          style={{height: height - 30, padding: 10}}>
          {this.loadDataViews()}
        </ScrollView>
        <Icon
          onPress={this.onPress}
          name='chevron-circle-up'
          color='white'
          type='font-awesome-5'
          size={15}
          containerStyle={{
            padding: 10,
          }}
        />
      </View>
    )
  }
}

export default IViewBody
