/* eslint-disable no-template-curly-in-string */
import React, {Component} from 'react'
import {
  StyleSheet,
  View,
  Linking,
  Alert,
  TouchableOpacity,
  ActivityIndicator,
  Clipboard,
} from 'react-native'
import {Text, Avatar} from 'react-native-elements'

import TimePointView from './TimePointView'

import find from 'lodash/find'

async function OpenURL (url) {
  const supported = await Linking.canOpenURL(url)
  if (supported) {
    await Linking.openURL(url)
  } else {
    Alert.alert('No app to open this URL: ${url}')
  }
}

class IViewLinkView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isMounted: false,
      title: '',
      description: '',
      url: '',
      image: '',
      isCopied: false,
      isNoted: false,
    }
  }
  componentDidMount () {
    var data = {
      key: '14f4e0102f66b176837f885406f83d6e',
      q: this.props.data.body,
    }
    fetch('https://api.linkpreview.net', {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(response => {
        this.setState({
          isMounted: true,
          title: response.title,
          description: response.description,
          url: response.url,
          image: response.image,
        })
      })
  }

  onPress = () => {
    OpenURL(this.state.url)
  }

  getLinkHeight = height => {
    return height / 8
  }
  copyText = () => {
    this.setState(
      {
        isCopied: true,
      },
      () => Clipboard.setString(this.props.data.body),
    )
    setTimeout(() => {
      this.setState({
        isCopied: false,
      })
    }, 1000)
  }

  noteText = () => {
    this.setState({
      isNoted: true,
    })
    this.props.onNoteCreate(this.props.data)
  }

  render () {
    const {width, height} = this.props
    const height_ = this.getLinkHeight(height)
    const avatarSize = height_ / 2.5
    const timeStampSize = height_ / 8
    const titleAndUrlFontSize = height_ / 6

    const linView = width - avatarSize - 40

    return (
      <View
        style={{
          borderRadius: 5,
          paddingHorizontal: 10,
          paddingTop: 5,
          paddingBottom: 2,
          backgroundColor: '#167D7F',
        }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 1,
          }}>
          <Text
            onPress={this.noteText}
            style={[
              styles.copyTextStyle,
              {
                fontSize: titleAndUrlFontSize / 1.5,
              },
            ]}>
            {!find(this.props.notes, this.props.data) ? 'Note' : 'Noted'}
          </Text>
          <Text
            onPress={this.copyText}
            style={[
              styles.copyTextStyle,
              {
                fontSize: titleAndUrlFontSize / 1.5,
              },
            ]}>
            {!this.state.isCopied ? 'Copy' : 'Copied'}
          </Text>
        </View>
        {this.state.isMounted ? (
          <TouchableOpacity onPress={this.onPress}>
            <View style={styles.viewStyle}>
              <Avatar
                source={{
                  uri: this.state.image,
                }}
                rounded
                avatarContainerStyle={styles.avatarContainerStyle}
                size={avatarSize}
              />
              <View
                style={{
                  marginHorizontal: 10,
                  width: linView,
                }}>
                <Text
                  numberOfLines={1}
                  style={[
                    styles.titleStyle,
                    {
                      fontSize: titleAndUrlFontSize,
                    },
                  ]}>
                  {this.state.title}
                </Text>
                <Text
                  numberOfLines={1}
                  style={[
                    styles.urlStyle,
                    {
                      fontSize: titleAndUrlFontSize,
                    },
                  ]}>
                  {this.state.url}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        ) : (
          <ActivityIndicator />
        )}

        <TimePointView
          timePoint={this.props.data.timepoint}
          timeStampSize={timeStampSize}
          onTimePointPress={this.props.onTimePointPress}
          color={'black'}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  copyTextStyle: {
    backgroundColor: 'white',
    padding: 2,
    borderRadius: 3,
    color: 'black',
    alignSelf: 'flex-end',
  },
  timepointStyle: {
    alignSelf: 'flex-end',
    color: 'grey',
  },
  avatarContainerStyle: {},
  viewStyle: {
    flexDirection: 'row',
  },
  urlStyle: {color: 'gold'},
  titleStyle: {
    fontWeight: 'bold',
    color: 'white',
  },
  textStyle: {
    color: '#243447',
  },
})

export default IViewLinkView
