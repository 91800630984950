import React, {Component} from 'react'
import {View, Dimensions} from 'react-native'
import reject from 'lodash/reject'
import orderBy from 'lodash/orderBy'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import cloneDeep from 'lodash/cloneDeep'

import VideoPlayer from './VideoPlayer'

import FileSaver from 'file-saver'

const WIDTH = Dimensions.get('window').width
const HEIGHT = Dimensions.get('screen').height

class IVPlayer extends Component {
  constructor (props) {
    super(props)
    const videoData = JSON.parse(atob(this.props.timeline))
    let timings = cloneDeep(videoData.timings)
    forEach(timings, item => {
      item.timepoint = this.getHHMMSSToSeconds(item.timepoint + '')
    })
    let sorted = orderBy(timings, ['timepoint'], ['asc'])
    this.state = {
      isIViewClosed: false,
      iViewNoteClosed: true,
      currentTime: 0,
      timelineIndex: 0,
      isFullScreen: false,
      notes: [],
      url: videoData.url,
      timings: sorted,
      title: this.props.title,
    }
  }
  handleIView = ref => {
    this.iViewRef = ref
  }

  handleIViewNote = ref => {
    this.iViewNoteRef = ref
  }

  onSwitcherPress = () => {
    if (this.state.isIViewClosed) {
      this.iViewRef.fadeIn(1000)
    } else {
      this.iViewRef.zoomOut(1000)
    }
    this.setState({
      isIViewClosed: !this.state.isIViewClosed,
    })
  }

  onNoteSwitcherPress = () => {
    if (!this.state.iViewNoteClosed) {
      this.iViewNoteRef.zoomOut(1000)
    } else {
      this.iViewNoteRef.fadeIn(1000)
    }
    this.setState({
      iViewNoteClosed: !this.state.iViewNoteClosed,
    })
  }

  videoRef = ref => {
    this.player = ref
    this.player.subscribeToStateChange(this.handleStateChange)
  }

  handleStateChange = (status, prevState) => {
    this.setState({
      currentTime: status.currentTime,
      isFullScreen: status.isFullscreen,
    })
  }

  getHHMMSSToSeconds = hms => {
    try {
      let a = hms.split(':')
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
      return seconds * 1000
    } catch (error) {
      return 0
    }
  }

  getData = () => {
    let filtered = this.state.timings
    if (this.state.timelineIndex === 0) {
      filtered = this.state.timings.filter((obj, index) => {
        if (
          parseInt(obj.timepoint / 1000) <= parseInt(this.state.currentTime)
        ) {
          return true
        } else {
          return false
        }
      })
    }
    return filtered
  }

  getUrl = () => {
    return this.state.url
  }

  getTitle = () => {
    return this.state.title
  }

  getNotes = () => {
    return this.state.notes
  }

  onTimeLineChange = index => {
    this.setState({
      timelineIndex: index,
    })
  }

  onTimePointPress = timepoint => {
    this.player.seek(timepoint / 1000)
  }

  onIViewControlPress = () => {
    this.onSwitcherPress()
  }

  onIViewNoteControlPress = () => {
    this.onNoteSwitcherPress()
  }

  onNoteCreate = note => {
    if (this.state.iViewNoteClosed) {
      this.iViewNoteRef.fadeIn(1000)
    }
    this.setState({
      notes: [...this.state.notes, note],
      iViewNoteClosed: false,
    })
  }

  onNoteRemove = note => {
    this.setState({
      notes: reject(this.state.notes, note),
    })
  }

  componentDidMount () {
    setTimeout(() => {
      if (this.state.isIViewClosed) {
        this.iViewRef.zoomOut(1000)
      } else {
        this.iViewRef.fadeIn(1000)
      }
      if (this.state.iViewNoteClosed) {
        this.iViewNoteRef.zoomOut(1000)
      } else {
        this.iViewNoteRef.fadeIn(1000)
      }
    }, 100)
  }

  onNotesExport = () => {
    let array = map(this.state.notes, 'body').join('\n')
    let blob = new Blob([array], {type: 'text/plain;charset=utf-8'})
    FileSaver.saveAs(blob, this.getTitle() + '.txt')
  }

  render () {
    const VIDEO_VIEW_WIDTH = this.state.isFullScreen ? WIDTH : 800

    const VIDEO_VIEW_HEIGHT = this.state.isFullScreen ? HEIGHT : 450

    const I_VIEW_WIDTH = VIDEO_VIEW_WIDTH / 4
    const I_VIEW_HEIGHT = VIDEO_VIEW_HEIGHT

    return (
      <View
        style={{
          width: VIDEO_VIEW_WIDTH,
          flex: 1,
        }}>
        <VideoPlayer
          fluid={false}
          width={VIDEO_VIEW_WIDTH}
          height={VIDEO_VIEW_HEIGHT}
          playsInline
          controls={true}
          src={this.getUrl()}
          title={this.getTitle()}
          reference={this.videoRef}
          iViewReference={this.handleIView}
          iViewNoteReference={this.handleIViewNote}
          videoHeight={VIDEO_VIEW_HEIGHT}
          videoWidth={VIDEO_VIEW_WIDTH}
          iViewWidth={I_VIEW_WIDTH}
          iViewHeight={I_VIEW_HEIGHT}
          data={this.getData()}
          notes={this.getNotes()}
          iViewNoteClosed={this.state.iViewNoteClosed}
          timeLine={this.state.timelineIndex}
          onTimePointPress={this.onTimePointPress}
          onTimeLineChange={this.onTimeLineChange}
          iViewClosed={this.state.isIViewClosed}
          onIconPress={this.onSwitcherPress}
          onNoteIconPress={this.onNoteSwitcherPress}
          onIViewControlPress={this.onIViewControlPress}
          onIViewNoteControlPress={this.onIViewNoteControlPress}
          onNoteCreate={this.onNoteCreate}
          onNoteRemove={this.onNoteRemove}
          notesExport={this.onNotesExport}
        />
      </View>
    )
  }
}

export default IVPlayer
