import React, {Component} from 'react'
import IVPlayer from './ivplayer/IVPlayer'

import './ivplayer/styles/video-react.css'

class PublicApp extends Component {
  render () {
    console.log(this.props.title)
    console.log(this.props.id)
    console.log(this.props.timeline)

    return (
      <>
        <style type='text/css'>{`
        @font-face {
          font-family: 'iv-player-icons';
          src: url(${require('./ivplayer/custom-icons/iv-player-icons.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'MaterialIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Brands';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Regular';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf')}) format('truetype');
        }
       @font-face {
          font-family: 'FontAwesome5_Solid';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf')}) format('truetype');
        }
      `}</style>
        <IVPlayer
          title={this.props.title}
          id={this.props.id}
          timeline={this.props.timeline}
        />
      </>
    )
  }
}

export default PublicApp
